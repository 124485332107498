@import 'mixins';

$defaultFont: 'myriad-pro',sans-serif;
$menuFont: 'myriad-pro',sans-serif;
$menuColor: #000000;
$menuBackColor: #ffffff;
$textColor: #333;

/*primary*/
$buttonTextColor: #fff;
$buttonColor: #a1aaa6;
$buttonBorderColor: #a1aaa6;

/*default*/
$buttonDefaultTextColor: #333;
$buttonDefaultColor: #fff;
$buttonDefaultBorderColor: #a1aaa6;

$searchColor: #c1c2c0;
$searchMobileColor: #434244;

/*checkout*/
$backgroundCheckout: #f5f5f5;

/*trash*/
$buttonTrashColor: #39c;

$topColor: #525659;

$topMenuBackColor: #fafaf6;
$headerBackColor: #fffefd;

$megamenuBackColor: #324049;
$megamenuColor: #ffffff;

$stockInStockColor: #009444;
$stockNoStockColor: #ae1f2c;
$stockOrderStockColor: #f7941e;
$stockDemandStockColor: #a1aaa6;

$adBackground: #e5e5e6 ;
$supportLinkTextBgColor: #f1f1f1;
$supportLinkIconSize: 25px;
$supportLinkPadding: 15px;
$supportLinkLeftPadding: $supportLinkIconSize + 3*$supportLinkPadding;
$supportLinkDividingLine: $supportLinkIconSize + 2*$supportLinkPadding;

html, body, form, fieldset, table, tr, td, img {
    font-family: $defaultFont;
}

/* Header */
.header-main {
    @include fromSizeSM() {
        height: 120px;
        }
    }

    .HeaderLogo {
        background: url('Images/Thermo-Floorlogo.svg') no-repeat;
        width: 257px;
        /* 257 / 56 */
        @include fromSizeSM() {
            width: 325px;
            height: 70px;
        }
    }

    a.header-logo {
        @include fromSizeSM() {
            /*position: initial;*/
            top: 60px;
            margin-left: 15px;
        }
    }

    .HeaderSearch,
    .small-cart-onpage {
        @include fromSizeSM() {
            top: 2em;
        }
    }

    .HeaderSearch table,
    a.cart-small-trigger.btn.btn-default,
    a.cart-small-goto-checkout.btn.btn-primary {
        @include fromSizeSM() {
            border-radius: 4px;
        }
    }

    .search-btn {
    }

    .HeaderSearch table .icon-search:before {
        @include fromSizeSM() {
            content: ' ';
        }
    }

    .HeaderSearch table .icon-search {
        height: 30px;
        width: 30px;
        background: url('Images/Sokefelt-vektor.svg') no-repeat;
    }
    /* Has to cover new search-icon*/
    #CloseSearchButton {
        width: 38px;
        right: 33px;
        top: 5px;
    }

    .cart-small.btn-group .cart-small-icon .icon-basket:before {
        @include fromSizeSM() {
            content: ' ';
        }
    }

    .cart-small.btn-group .cart-small-icon .icon-basket {
        height: 30px;
        width: 30px;
        background: url('Images/Handlevogn-vektor.svg') no-repeat;
    }

    header a.InfoMenu {
        @include fromSizeSM() {
            font-size: 14px;
            text-transform: uppercase;
        }
    }

header {
    @include fromSizeSM() {
        background: linear-gradient(to bottom, $topMenuBackColor 0px, $topMenuBackColor 38px, #000 39px, $headerBackColor 39px, $headerBackColor 100%);
    }
}

.body-checkout header {
    @include fromSizeSM() {
        background: $backgroundCheckout;
    }
}


    header .top-menu {
        @include fromSizeSM() {
            height: 2.7em;
        }
    }

    header .login-container {
        @include fromSizeSM() {
        }
    }

    header .TopMenu,
    header .TopMenu h3,
    header .TopMenu h3 a.InfoMenu {
        @include fromSizeSM() {
            height: 100%;
        }
    }

    .InfoMenu {
        color: $menuColor;
    }

    .InfoMenu.kundesenter {
        @include fromSizeSM() {
            padding-left: 20px;
            background: url(Images/Kundesenter-vektor.svg);
            background-repeat: no-repeat;
            background-position: 0px 1px;
            background-size: 16px;
        }
    }

    .LogInButton {
        color: $menuColor;
        background-position-y: 3px;
    }



.btn-primary {
    color: $buttonTextColor;
    background-color: $buttonColor;
    border-color: $buttonBorderColor;
    text-transform: uppercase;

    &:hover {
        background-color: $megamenuBackColor;
        border-color: $megamenuBackColor;
    }
}

    .btn-default {
        color: $buttonDefaultTextColor;
        background-color: $buttonDefaultColor;
        border-color: $buttonDefaultBorderColor;
    }

    .btn:focus,
    .btn:active:focus,
    .btn.active:focus,
    .btn.focus,
    .btn:active.focus,
    .btn.active.focus {
        outline: 0px;
    }

.list-buy-button {
    font-weight: bold;
}

.mc-overlay {
    background: transparent;
}

.menu-container {

    @include fromSizeSM() {
        margin-bottom: 10px;
        padding-top: 0;
        background: $headerBackColor;
        color: $menuColor;
        border-bottom: 1px solid $buttonColor;
        box-shadow: 0px 10px 30px rgba(0,0,0,0.2);
    }
}
.SubMenuLink,
.navbar-default .navbar-nav > li > a {
    @include fromSizeSM() {
        background: $menuBackColor;
        color: $menuColor;
    }

    font-family: $menuFont;
    font-size: 18px;
    font-weight: bold;
}


    .dropdown-menu {
        background-color: $megamenuBackColor;
    }

.SubMenuLink:hover,
.dropdown .mcm-fw .open,
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    background: $megamenuBackColor;
    color: $megamenuColor;
}

    .SubMenuBox,
    .navbar-default .navbar-nav > li > a {
        &:hover,
        &:focus,
        &:active {
            background: $megamenuBackColor;
        }
    }

    .mcm .dropdown.mcm-fw .dropdown-menu {
        box-shadow: none !important;
    }


    .navbar-nav > li:first-child > a {
        @include fromSizeMD() {
            padding-left: 15px;
        }
    }

    .navbar-nav > li:last-child > a {
        @include fromSizeMD() {
            padding-right: 15px;
        }
    }


    .dropdown-menu a,
    .dropdown-menu .title,
    .dropdown-menu .menu-show-all {
        color: $megamenuColor;
    }

    .mcm .dropdown.mcm-fw .dropdown-menu {
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,2/10);
        border-top: none;
    }

    .footer-container {
        background: $menuColor;
        color: white;
    }

    .copyright-container {
        background: $buttonColor;
        color: white;
        padding: 10px 0;
        height: 200px;
    }

    .copyright-container a {
        color: white;
    }

    a.cart-small-goto-checkout.btn.btn-primary {
        background: $buttonColor;
        border: none;
        &:hover{
            background: $megamenuBackColor;
        }
    }

    .mobilemenu-sm .cart-small-icon,
    .mobilemenu-md .cart-small-icon,
    .mobilemenu-lg .cart-small-icon {
        color: #3d3d3d;
    }

    .free-shipping-counter {
        background: $buttonColor;
    }

.BreadCrumbTitle
{
    font-weight: bold;
}

.ProductMenu a {
    color: $menuColor;
}

.ProductMenu .Level1Selected {
    background: $megamenuBackColor;
    border-radius: 4px;
}

    .ProductMenu .Level1Selected a {
        color: white;
    }

    .ProductMenu .Level2Selected {
        background-image: url(Images/menu-arrow.png);
        background-repeat: no-repeat;
        background-position: 30px center;

        @include toSizeMD {
            background-position-x: 6px;
        }
    }

    .ProductMenu .Level3Selected {
        background-image: url(Images/menu-arrow.png);
        background-repeat: no-repeat;
        background-position: 45px center;

        @include toSizeMD {
            background-position-x: 16px;
        }
    }

    .ProductMenu .Level4Selected {
        background-image: url(Images/menu-arrow.png);
        background-repeat: no-repeat;
        background-position: 45px center;

        @include toSizeMD {
            background-position-x: 26px;
        }
    }

    .section-menu-row {
        background: $menuColor;
        border-top: 1px solid $buttonColor;
        border-bottom: 1px solid $buttonColor;
    }

    .section-menu-item:hover {
        border-top: 2px solid $buttonColor;
    }
    /* ProductInfo */

.ProductInfo .heading-container h1 {
    font-family: $defaultFont;

    @include fromSizeSM() {
        font-size: 26px;
    }
}

.ProductInfo .heading-container h2 {
    font-family: $defaultFont;

    @include fromSizeSM() {
        font-size: 26px;
    }
}

    .ProductInfo .section-header span::after {
        background: $buttonColor;
    }

    .ProductInfo .buy-product {
        @include fromSizeSM() {
            padding-top: 10px;
            padding-bottom: 10px;
            text-transform: uppercase;
            width: auto;
            border-radius: 4px;
        }
    }

    .section-menu {
        background: $megamenuBackColor;
    }

    .section-menu-item {
        font-family: $defaultFont;
    }

    .section-header span {
        font-family: $defaultFont;
    }

.ProductInfo .SupportLink {
    padding: $supportLinkPadding $supportLinkPadding $supportLinkPadding $supportLinkLeftPadding;
    font-size: 20px;
    text-transform: uppercase;
    border: 1px $megamenuBackColor solid;
    background: url('Images/Last ned-vektor.svg') no-repeat $supportLinkPadding #{$supportLinkPadding}/#{$supportLinkIconSize} $supportLinkIconSize, linear-gradient(to right, $buttonColor 0%, $buttonColor, $supportLinkDividingLine, $megamenuBackColor $supportLinkDividingLine, $supportLinkTextBgColor $supportLinkDividingLine+1px, $supportLinkTextBgColor 100% );
    /*background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: -25% 50%;*/
    color: $buttonColor;
    margin-bottom: $supportLinkPadding;
}


.BreadCrumb a:last-child {
    color: $buttonColor;
    font-weight: bold;
}
.Layout3Element {
    text-align: left;
}

.Layout3Element .AddHeader1 {
    font-weight: bold;
}

.Layout3Element .InfoOverlay {
    @include fromSizeSM() {
        padding: 10px;
    }
}

.Layout3Element .AddPriceContainer {
    @include fromSizeSM() {
        padding-left: 0px;
    }
}

.Layout3Element .favorite-button,
.Layout3Element .ad-buy-button {

    @include fromSizeSM() {
        background: $megamenuBackColor;
        color: white;
    }
}
.Layout3Element .ad-buy-button
{
    text-align: left;
    padding-left: 12px;
    text-transform: uppercase;
}

.Layout3Element .fav-remove-button {
    background: transparent;

    @include fromSizeSM() {
        background: $buttonTrashColor;
        color: #fff;
        border-left: 1px solid;
    }
}


/* Stock */
.InStock, 
.NoStock,
.StockOrder,
.StockDemand
{
    border-radius: 50%;
    width: 26px;
    padding: 3px;
}


.InStock {
    background: $stockInStockColor;
}

.NoStock {
    background: $stockNoStockColor;
}

.StockOrder {
    background: $stockOrderStockColor;
}

.StockDemand {
    background: $stockDemandStockColor;
}


@include toSizeSM() {
    .pub-favorites .Layout3Element .fav-remove-button {
        background: $buttonTrashColor;
        color: #fff;
        border-left: 1px solid;
    }

    .pub-favorites .Layout3Element .ad-buy-button {
        background: transparent;
        background: $buttonColor;
        color: white;
    }
}

    .pub-favorites .Layout3Element .ad-buy-button,
    .pub-favorites .Layout3Element .fav-remove-button {
        @include toSizeSM() {
            display: block !important;
        }

        display: block;
        z-index: 1;
    }

    .pub-favorites .Layout3Element .favorite-button {
        @include toSizeSM() {
            display: none;
        }
    }

    .btn-favorite {
        font-weight: 300;
        font-family: $menuFont;
        background: $buttonDefaultColor;
        color: $buttonDefaultTextColor;

        &:hover,
        &:active,
        &:focus,
        &:active:focus {
            background: lighten($buttonDefaultColor, 10%);
            outline: none;
        }
    }

    .favorites .btn .glyphicon {
        color: $buttonColor;
    }

    .remove-favorites-text {
        display: none;
    }


    .Layout3Element .ad-buy-button {
        border-right-color: white;
    }

    .buy-product,
    .scoll-config-summary {
        font-weight: 300;
        font-family: $menuFont;
        background: $buttonColor;
        color: white;


        &:hover,
        &:active,
        &:focus,
        &:active:focus {
            background: $megamenuBackColor;
            color: white;
            outline: none;
        }
    }




    .TableList .BuyButtonSmall,
    .TableList .VariantShowMore {
        border-radius: 4px;
        background: $buttonColor;
        font-family: $menuFont;
        font-size: 18px;

        &:hover {
            background: darken($buttonColor, 10%);
        }
    }

    .TableList .BuyButtonSmall.list-vbuy-btn,
    .TableList .VariantShowMore.list-vbuy-btn {
        font-family: $defaultFont;
    }

    .TableList .BuyButtonSmallDisabled {
        border-radius: 0;
        font-family: $menuFont;
        font-size: 18px;
    }

    .PrdListCellsPagingTop a,
    .PrdListCellsPagingBottom a,
    .FieldPaging a {
        &:hover {
            background: $buttonColor;
        }
    }

    .PrdListCellsPagingTop a.SelectedPagingIndex,
    .PrdListCellsPagingBottom a.SelectedPagingIndex,
    .FieldPaging a.SelectedPagingIndex {
        background: $buttonColor;
    }

    .less-qty,
    .more-qty {
        border-radius: 50%;
        border-color: $menuColor;
        background: transparent;
        color: $menuColor;
        margin-top: 6px;

        &:hover {
            background: #eee;
        }
    }

    .less-qty {
        margin-right: 5px;
    }

    .more-qty {
        margin-left: 5px;
    }

    .product-info-container .QuantityTextBox {
        background: transparent;
        border-radius: 50%;
        width: 54px;
        height: 54px;
        border: 1px solid $menuColor;
        color: $menuColor;
    }
    /* the checked style using the :checked pseudo class */
    .radio-inline input[type="radio"]:checked + span:before {
        background-color: $buttonColor;
    }

    .list-type-button {
        color: $menuColor;
    }

    .search-btn {
        /*color: $searchMobileColor;*/
        @include fromSizeSM {
            color: $searchColor;
            font-size: 28px;
        }
    }

    .search-btn-text {
        display: none;
    }

    .checkout,
    .checkout-left,
    .checkout-right {
        background-color: $backgroundCheckout;
    }

    .body-checkout .PageContainerBorder {
        @include toSizeLG {
            background-color: $backgroundCheckout;
        }
    }
    /*Nyhetsbrev*/
    .SubscribeButton.ui-btn {
        background: $buttonColor;
        font-family: $defaultFont;

        &:hover {
            background: lighten($buttonColor, 10%);
        }
    }
    /* ads */
    .Layout3Element {
        background: $adBackground;
    }

    .Layout3Element .AttributeNewIcon,
    .Layout3Element .YouSavePercentLabel {
        background: $megamenuBackColor;
        color: #ffffff;
    }